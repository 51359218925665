<template>
  <div class="myphoto">
    <van-nav-bar
      title="我的证件照"
      left-arrow
      fixed
      @click-left="goBack"
      @click-right="isDropdownMenu = !isDropdownMenu"
    >
      <template #right>
        <van-icon size="22" name="apps-o" />
      </template>
    </van-nav-bar>
    <div
      class="downMenuModal"
      v-if="isDropdownMenu"
      @click="isDropdownMenu = false"
    >
      <div v-if="isDropdownMenu" class="dropdownMenu">
        <div class="menuItem" @click="$router.push('/')">
          <span>首页</span>
        </div>
        <div class="menuItem" @click="$router.push('/xuanke')">
          <span>选课中心</span>
        </div>
        <div class="menuItem" @click="$router.push('/tiku')">
          <span>题库</span>
        </div>
        <div class="menuItem" @click="$router.push('/homeLiveList')">
          <span>直播</span>
        </div>
        <div class="menuItem" @click="$router.push('/learning')">
          <span>学习中心</span>
        </div>
        <div class="menuItem" @click="downApp">
          <span>下载APP</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="list-box">
        <div
          class="child-box"
          @click="setPhotoDetail(item)"
          v-for="(item, index) in data"
          :key="index"
          :style="index % 2 == 0 ? '' : 'margin-left:15px'"
        >
          <img :src="item.identificationPhotoUrl" class="child-img" />
          <div class="title-box">{{ item.identificationPhotoName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyIdentificationPhotos } from "@/api/photo.js";
export default {
  name: "myphoto",
  data() {
    return {
      isDropdownMenu: false,
      data: [],
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    downApp() {
      window.location.href = "https://www.saen.com/app/";
    },
    goBack() {
      this.$router.go(-1);
    },
    setRoute(val) {
      if (val == 1) {
      } else {
      }
    },
    init() {
        this.$toast.loading({
            message: "加载中...",
            duration: 0,
            forbidClick: true,
        });
      getMyIdentificationPhotos(1, 1000).then((res) => {
        this.$toast.clear();
        this.data = res.data.data.list;
      });
    },
    setPhotoDetail(item) {
      console.log(item);
      localStorage.setItem("photoDetail", JSON.stringify(item));
      this.$router.push({
        path: "/photoDetail",
        query: {},
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.myphoto {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.downMenuModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .dropdownMenu {
    position: fixed;
    right: 10px;
    top: 44px;
    background-color: #fff;
    z-index: 999;
    width: 100px;
    min-height: 200px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);

    .menuItem {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 20px;
    }
    .menuItem5 {
      color: #ef8f27;
    }
  }
}
.content-box {
  width: 100%;
  height: calc(100vh - 46px);
  margin-top: 46px;
  background-color: #f9f7f7;
  overflow: hidden;
  overflow-y: auto;
  .list-box {
    width: calc(100% - 60px);
    margin-left: 30px;
    //height: 100%;
    display: flex;
    flex-wrap: wrap;
    .child-box {
      background-color: white;
      margin-top: 20px;
      width: calc(50% - 10px);
      height: 180px;
      .child-img {
        width: calc(100% - 30px);
        height: 140px;
        margin-left: 15px;
        margin-top: 5px;
      }
      .title-box {
        width: 100%;
        height: 25px;
        line-height: 25px;
        text-align: center;
      }
    }
  }
}
</style>
